import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { onError } from '../assets/scripts/errorLib';

/**
 * User login form
 * @returns {HTMLElement} html for login block
 */
export default function Logout() {

    /**
     * @typedef {{
     * onkeyup: number
     * }} KeyEvent
     */

    // adjusts the page header
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                const user = await Auth.currentSession();

                if (user) {
                    await handleLogout();

                    location.href = '/';
                }
            } catch (e) {
                if (e !== 'No current user') {
                    onError(e);
                }
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, []);

    /**
     * Log off website
     * @returns {void}
     */
    const handleLogout = async () => {
        try {
            await Auth.signOut({ global: true });
        } catch (error) {
            console.log('error signing out: ', error);
        }
    };

    return (
        <></>
    );
}
